import React, { useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import './App.css';

const gaId = "UA-36738577-1";

function App() {
  const vantaRef = useRef(null);

  useEffect(() => {
    ReactGA.initialize(gaId);
    ReactGA.pageview(window.location.pathname);

    const loadVanta = () => {
      const script = document.createElement('script');
      script.src = "https://cdnjs.cloudflare.com/ajax/libs/three.js/r121/three.min.js";
      script.onload = () => {
        const vantaScript = document.createElement('script');
        vantaScript.src = "https://cdn.jsdelivr.net/npm/vanta@latest/dist/vanta.waves.min.js";
        vantaScript.onload = () => {
          if (window.VANTA) {
            window.VANTA.WAVES({
              el: vantaRef.current,
              mouseControls: true,
              touchControls: true,
              gyroControls: true,
              minHeight: 200.0,
              minWidth: 200.0,
              scale: 1.0,
              scaleMobile: 1.0,
              color: 0x343434,
              shininess: 4.0,
              waveHeight: 14.0,
              waveSpeed: 0.9,
              zoom: 0.65,
            });
            vantaRef.current.style.transition = "opacity 1s ease-in-out";
            vantaRef.current.style.opacity = "1";
          }
        };
        document.body.appendChild(vantaScript);
      };
      document.body.appendChild(script);
    };

    vantaRef.current.style.opacity = "0";
    vantaRef.current.style.backgroundColor = "#000000";
    loadVanta();

    return () => {
      if (window.VANTA) {
        window.VANTA.destroy();
      }
    };
  }, []);

  return (
    <main ref={vantaRef} style={{ backgroundColor: '#000000' }}>
      <h1>David Alson</h1>
      <ul>
        <a href="https://www.linkedin.com/in/alson/">LinkedIn</a>
      </ul>
    </main>
  );
}

export default App;
